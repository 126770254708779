@import "_colors.less";
@import "_variables.less";

@font-face {
  font-family: 'RobotoDraft';
  font-style: normal;
  font-weight: 400;
  //src: local('RobotoDraft'), local('RobotoDraft-Regular'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/robotodraft/v1/0xES5Sl_v6oyT7dAKuoni4gp9Q8gbYrhqGlRav_IXfk.woff2) format('woff2'), url(https://fonts.gstatic.com/s/robotodraft/v1/0xES5Sl_v6oyT7dAKuoni7rIa-7acMAeDBVuclsi6Gc.woff) format('woff');
  src: local('RobotoDraft'),
       local('RobotoDraft-Regular'),
       local('Roboto-Regular'),
       url('@{material-font-path}/RobotoDraftRegular.woff2') format('woff2'),
       url('@{material-font-path}/RobotoDraftRegular.woff') format('woff');
}

@font-face {
  font-family: 'RobotoDraft';
  font-style: normal;
  font-weight: 500;
  //src: local('RobotoDraft Medium'), local('RobotoDraft-Medium'), local('Roboto-Medium'), url(https://fonts.gstatic.com/s/robotodraft/v1/u0_CMoUf3y3-4Ss4ci-VwXJuJo8UJJfpGKt7pXjBv4s.woff2) format('woff2'), url(https://fonts.gstatic.com/s/robotodraft/v1/u0_CMoUf3y3-4Ss4ci-VwaTA90I55Xt7owhZwpPnMsc.woff) format('woff');
  src: local('RobotoDraft Medium'),
       local('RobotoDraft-Medium'),
       local('Roboto-Medium'),
       url('@{material-font-path}/RobotoDraftMedium.woff2') format('woff2'),
       url('@{material-font-path}/RobotoDraftMedium.woff') format('woff');
}

@font-face {
  font-family: 'RobotoDraft';
  font-style: normal;
  font-weight: 700;
  //src: local('RobotoDraft Bold'), local('RobotoDraft-Bold'),  local('Roboto-Bold'), url(https://fonts.gstatic.com/s/robotodraft/v1/u0_CMoUf3y3-4Ss4ci-Vwf79_ZuUxCigM2DespTnFaw.woff2) format('woff2'), url(https://fonts.gstatic.com/s/robotodraft/v1/u0_CMoUf3y3-4Ss4ci-VwRbnBKKEOwRKgsHDreGcocg.woff) format('woff');
  src: local('RobotoDraft Bold'),
       local('RobotoDraft-Bold'),
       local('Roboto-Bold'),
       url('@{material-font-path}/RobotoDraftBold.woff2') format('woff2'),
       url('@{material-font-path}/RobotoDraftBold.woff') format('woff');
}

@font-face {
  font-family: 'RobotoDraft';
  font-style: italic;
  font-weight: 400;
  //src: local('RobotoDraft Italic'), local('RobotoDraft-Italic'), local('Roboto-Italic'), url(https://fonts.gstatic.com/s/robotodraft/v1/er-TIW55l9KWsTS1x9bTfgeOulFbQKHxPa89BaxZzA0.woff2) format('woff2'), url(https://fonts.gstatic.com/s/robotodraft/v1/er-TIW55l9KWsTS1x9bTfoo3ZslTYfJv0R05CazkwN8.woff) format('woff');
  src: local('RobotoDraft Italic'),
       local('RobotoDraft-Italic'),
       local('Roboto-Italic'),
       url('@{material-font-path}/RobotoDraftItalic.woff2') format('woff2'),
       url('@{material-font-path}/RobotoDraftItalic.woff') format('woff');
}
